<template>
    <div class="page-container">
        <br />
        <img src="/img/logo-stock.png" alt="stock.ftvaccess.fr" />
        <br /><br />
        <div class="login md-alignment-top-center md-layout">
            <md-card md-with-hover class="md-layout-item md-size-30">
                <md-card-header>
                    <h1 class="md-title">Reset de mot de passe</h1>
                </md-card-header>
                <form @submit.prevent="submit">

                    <md-card-content>
                        <md-vuelidated>
                            <label>Votre nouveau mot de passe</label>
                            <md-input v-model.trim="form.password" type="password"></md-input>
                            <md-vuelidated-msg constraint="required">Merci de saisir votre mot de passe.</md-vuelidated-msg>
                            <md-vuelidated-msg constraint="password">Votre mot de passe doit contenir au minimum 9 caractères, contenir au minimum une lettre Majuscule, 1 lettre Minuscule et 1 chiffre</md-vuelidated-msg>
                        <span class="md-helper-text">Votre mot de passe ne doit pas avoir été utilisé lors des derniers 18 mois<br />
Il doit faire au minimum 9 caractères, contenir au minimum une lettre Majuscule, 1 lettre Miniscule, 1 chiffre</span>
                        </md-vuelidated>
                    </md-card-content>

                    <md-card-actions>
                        <br />
                        <br /><br /><br /><br/><br /><br /><br />
                        <div>
                            <div>
                                <md-button class="md-raised md-dense" type="submit">Enregistrer mon mot de passe</md-button>
                            </div>
                            <div style="margin-top: 10px;">
                                <md-button class="md-raised md-accent md-dense" @click="$router.push('/lost');">Obtenir un nouveau lien</md-button> 
                            </div>
                        </div>
                    </md-card-actions>
                </form>
                
            </md-card>            
        </div>
    </div>
</template>
      

<script>
import {required, helpers} from 'vuelidate/lib/validators'
const password = helpers.regex('password', /(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{9,}/)

import {resetPassword} from '@/api/user'

export default {
  name: 'Reset',
  data(){
      return {
          loader: null,
          form:{
              password:'',
              email:'',
              reset_token:''
          }
      }
  },
  validations:{
      form:{
          password:{
              required,
              password
          }
      }
  },
  methods:{
      submit(){
          this.$v.$touch();
          if(!this.$v.$invalid){
              // submission
              this.loader=this.$loading.show();
              resetPassword(this.form)
              .then((data) => {
                    this.$alert(data.message,'Success','success');
                    // sending  to login :
                    this.loader.hide();
                    this.$router.push({path: '/login'})
              }).catch((e) => {
                  this.loader.hide();
                  console.log(e);
                  this.$alert(e.response.data.error,'Error','error');
              })
          }
      }
  },
    mounted(){
        let email = this.$route.query.account;
        let token = this.$route.query.token;
        if(email==null || token==null){
            this.$alert('Il vous faut obtenir un lien par email pour vous accéder à cette page','Error','error');
            this.$router.push('/login');
        }
        else{
            this.form.email=email;
            this.form.reset_token=token;
        }
    }
  
}
</script>
<style lang="scss" scoped>
.md-app {
    min-height: 100vh;
    
    border: 1px solid rgba(#000, .12);
  }
img.logo{
    max-height: 50px;
}
.md-layout-item{
    flex-basis: 400px;
    min-width: 300px;
    max-width: 400px;
}
</style>